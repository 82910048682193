var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", {
      staticClass: "mask",
      on: {
        click: function($event) {
          return _vm.$emit("close")
        }
      }
    }),
    _c("div", { staticClass: "preview-area" }, [
      _c(
        "div",
        { staticClass: "content" },
        [
          _vm._l(_vm.files, function(file, index) {
            return _c("img", {
              key: index,
              staticClass: "pre-content",
              attrs: { src: file }
            })
          }),
          _c("div", { staticClass: "water" })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }