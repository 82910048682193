var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "search-area" }, [
        _c("div", { staticClass: "title" }, [_vm._v("印刷订单")]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "name" }, [_vm._v("订单号")]),
          _c(
            "div",
            { staticClass: "input" },
            [
              _c("el-input", {
                model: {
                  value: _vm.orderCode,
                  callback: function($$v) {
                    _vm.orderCode = $$v
                  },
                  expression: "orderCode"
                }
              })
            ],
            1
          ),
          _c("div", { staticClass: "name mar-left" }, [_vm._v("下单时间")]),
          _c(
            "div",
            { staticClass: "input" },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetime",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  placeholder: "选择日期时间"
                },
                model: {
                  value: _vm.beginTime,
                  callback: function($$v) {
                    _vm.beginTime = $$v
                  },
                  expression: "beginTime"
                }
              })
            ],
            1
          ),
          _c("div", { staticClass: "space" }, [_vm._v("至")]),
          _c(
            "div",
            { staticClass: "input" },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetime",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  placeholder: "选择日期时间"
                },
                model: {
                  value: _vm.endTime,
                  callback: function($$v) {
                    _vm.endTime = $$v
                  },
                  expression: "endTime"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "search-button", on: { click: _vm.search } },
            [_c("i", { staticClass: "el-icon-search" }), _vm._v("搜索 ")]
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "order-class-list" },
        _vm._l(_vm.stateList, function(item) {
          return _c(
            "div",
            {
              key: item.index,
              class: [
                "order-class-item",
                { active: _vm.selectedIndex === item.index }
              ],
              on: {
                click: function($event) {
                  return _vm.changeStatus(item)
                }
              }
            },
            [_vm._v(" " + _vm._s(item.desc) + " ")]
          )
        }),
        0
      ),
      _vm._l(_vm.orderList, function(item, index) {
        return _c(
          "div",
          {
            key: index,
            staticStyle: {
              "margin-bottom": "30px",
              "background-color": "white",
              padding: "20px"
            }
          },
          [
            _c(
              "el-row",
              [
                _c("el-col", { attrs: { span: 6 } }, [
                  _c("div", [_vm._v("订单编号:" + _vm._s(item.orderNo))])
                ]),
                _c("el-col", { attrs: { span: 8 } }, [
                  _c("div", [_vm._v("下单人:" + _vm._s(item.userName))])
                ]),
                _c("el-col", { attrs: { span: 8 } }, [_c("div")])
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c("el-col", { attrs: { span: 6 } }, [
                  _c("div", [_vm._v("下单时间:" + _vm._s(item.createTime))])
                ]),
                _c("el-col", { attrs: { span: 8 } }, [
                  _c("div", [_vm._v("联系电话:" + _vm._s(item.userPhone))])
                ]),
                _c("el-col", { attrs: { span: 8 } }, [_c("div")])
              ],
              1
            ),
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: {
                  data: item.itemList,
                  "header-cell-style": {
                    "background-color": "#fafafa"
                  }
                }
              },
              [
                _c("el-table-column", {
                  attrs: { label: "货品规格", width: "200" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("div", [_vm._v(_vm._s(scope.row.productName))]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("skuCodeInfo")(scope.row.skuCode)
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                }),
                _c("el-table-column", {
                  attrs: { prop: "designFile", label: "文件", width: "200" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.sourceWorkId
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "preview-button",
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function($event) {
                                        return _vm.previewFile(
                                          scope.row.designFile
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(" 文件预览 ")]
                                )
                              : _vm._e(),
                            scope.row.designFile && !scope.row.sourceWorkId
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "warning" },
                                    on: {
                                      click: function($event) {
                                        return _vm.downLoadFile(
                                          scope.row.designFile
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("文件下载")]
                                )
                              : _vm._e(),
                            !scope.row.designFile
                              ? _c("span", [_vm._v("未上传")])
                              : _vm._e()
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                }),
                _c("el-table-column", {
                  attrs: { prop: "price", label: "货品金额", width: "100" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "quantity", label: "数量", width: "100" }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "orderItemPayableAmount",
                    label: "订单金额",
                    width: "200"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    Number(item.orderAmount) +
                                      Number(scope.row.freight)
                                  ) +
                                  " "
                              )
                            ]),
                            _c("div", [
                              _vm._v("(运费:" + _vm._s(scope.row.freight) + ")")
                            ])
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                }),
                _c(
                  "el-table-column",
                  { attrs: { label: "发票状态", width: "200" } },
                  [
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("invoiceStatusDesc")(item.invoiceStatus)
                          ) +
                          " "
                      )
                    ]
                  ],
                  2
                ),
                _c(
                  "el-table-column",
                  { attrs: { label: "订单状态", width: "200" } },
                  [
                    [
                      _vm._v(
                        _vm._s(_vm._f("orderStatusDesc")(item.orderStatus))
                      )
                    ]
                  ],
                  2
                ),
                _c(
                  "el-table-column",
                  { attrs: { prop: "", label: "其他", width: "100" } },
                  [
                    [
                      _c(
                        "div",
                        { staticClass: "scope-div" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "warning" },
                              on: {
                                click: function($event) {
                                  return _vm.detailInfo(item)
                                }
                              }
                            },
                            [_vm._v(" 订单详情 ")]
                          )
                        ],
                        1
                      ),
                      !item.invoiceStatus && item.orderStatus != "1"
                        ? _c(
                            "div",
                            { staticClass: "scope-div" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "warning" },
                                  on: {
                                    click: function($event) {
                                      return _vm.addInvoice(item)
                                    }
                                  }
                                },
                                [_vm._v(" 申请开票 ")]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      item.orderStatus == "1"
                        ? _c(
                            "div",
                            { staticClass: "scope-div" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "warning" },
                                  on: {
                                    click: function($event) {
                                      return _vm.toPay(item)
                                    }
                                  }
                                },
                                [_vm._v(" 去付款 ")]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      item.orderStatus == "5"
                        ? _c(
                            "div",
                            { staticClass: "scope-div" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "warning" },
                                  on: {
                                    click: function($event) {
                                      return _vm.confirmReceipt(item)
                                    }
                                  }
                                },
                                [_vm._v(" 确认收货 ")]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      item.invoiceStatus == 2
                        ? _c(
                            "div",
                            { staticClass: "scope-div" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "warning" },
                                  on: {
                                    click: function($event) {
                                      return _vm.downLoadInvoice(
                                        item.invoiceUrl
                                      )
                                    }
                                  }
                                },
                                [_vm._v(" 下载发票 ")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  ],
                  2
                )
              ],
              1
            )
          ],
          1
        )
      }),
      _c(
        "div",
        { attrs: { align: "right" } },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.page,
              "page-sizes": [10, 20, 30, 40],
              "page-size": _vm.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择发票",
            visible: _vm.showselectInvoice,
            width: "50%"
          },
          on: {
            "update:visible": function($event) {
              _vm.showselectInvoice = $event
            }
          }
        },
        [
          _c(
            "div",
            { attrs: { align: "right" } },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.$router.push({ name: "InvioceInfo" })
                    }
                  }
                },
                [_vm._v(" 管理 ")]
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.invoice, border: "" }
            },
            [
              _c("el-table-column", {
                attrs: { width: "180", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: scope.row.id },
                            model: {
                              value: _vm.selectedInvoceInfo,
                              callback: function($$v) {
                                _vm.selectedInvoceInfo = $$v
                              },
                              expression: "selectedInvoceInfo"
                            }
                          },
                          [_c("span")]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "ifVat", label: "类型", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("invoiceType")(scope.row.ifVat)) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "vatInvoiceTitle", label: "发票抬头" }
              })
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.showselectInvoice = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.commitChange } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "订单详情",
            visible: _vm.showOrderDetail,
            width: "50%"
          },
          on: {
            "update:visible": function($event) {
              _vm.showOrderDetail = $event
            }
          }
        },
        [
          _c("h4", [_vm._v("基本信息")]),
          _c("div", { staticClass: "info-item" }, [
            _vm._v("订单号：" + _vm._s(_vm.orderDetail.orderNo))
          ]),
          _c("div", { staticClass: "info-item" }, [
            _vm._v(
              " 支付方式：" +
                _vm._s(_vm._f("payTypeDesc")(_vm.orderDetail.payType)) +
                " "
            )
          ]),
          _c("div", { staticClass: "info-item" }, [
            _vm._v("下单人：" + _vm._s(_vm.orderDetail.userName))
          ]),
          _c("div", { staticClass: "info-item" }, [
            _vm._v("联系电话：" + _vm._s(_vm.orderDetail.userPhone))
          ]),
          _c("div", { staticClass: "info-item" }, [
            _vm._v("收货地址：" + _vm._s(_vm.orderDetail.deliveryAddress))
          ]),
          _c("div", { staticClass: "info-item" }, [
            _vm._v("订单备注：" + _vm._s(_vm.orderDetail.orderRemark))
          ]),
          _vm.orderDetail.invoice
            ? [
                _vm.orderDetail.invoice.ifVat
                  ? _c("div", { staticClass: "info-item" }, [
                      _vm._v(
                        " 发票类型：" +
                          _vm._s(
                            _vm._f("invoiceType")(_vm.orderDetail.invoice.ifVat)
                          ) +
                          " "
                      )
                    ])
                  : _vm._e(),
                _vm.orderDetail.invoice.vatTaxpayerNum
                  ? _c("div", { staticClass: "info-item" }, [
                      _vm._v(
                        " 纳税人识别号：" +
                          _vm._s(_vm.orderDetail.invoice.vatTaxpayerNum) +
                          " "
                      )
                    ])
                  : _vm._e(),
                _vm.orderDetail.invoice.vatInvoiceTitle
                  ? _c("div", { staticClass: "info-item" }, [
                      _vm._v(
                        " 发票抬头：" +
                          _vm._s(_vm.orderDetail.invoice.vatInvoiceTitle) +
                          " "
                      )
                    ])
                  : _vm._e(),
                _vm.orderDetail.invoice.vatCompanyAddress
                  ? _c("div", { staticClass: "info-item" }, [
                      _vm._v(
                        " 注册地址：" +
                          _vm._s(_vm.orderDetail.invoice.vatCompanyAddress) +
                          " "
                      )
                    ])
                  : _vm._e(),
                _vm.orderDetail.invoice.vatTelphone
                  ? _c("div", { staticClass: "info-item" }, [
                      _vm._v(
                        " 注册电话：" +
                          _vm._s(_vm.orderDetail.invoice.vatTelphone) +
                          " "
                      )
                    ])
                  : _vm._e(),
                _vm.orderDetail.invoice.vatBankName
                  ? _c("div", { staticClass: "info-item" }, [
                      _vm._v(
                        " 开户行：" +
                          _vm._s(_vm.orderDetail.invoice.vatBankName) +
                          " "
                      )
                    ])
                  : _vm._e(),
                _vm.orderDetail.invoice.vatBankAccount
                  ? _c("div", { staticClass: "info-item" }, [
                      _vm._v(
                        " 银行帐号：" +
                          _vm._s(_vm.orderDetail.invoice.vatBankAccount) +
                          " "
                      )
                    ])
                  : _vm._e()
              ]
            : _vm._e(),
          _c("div", { staticClass: "info-item" }, [
            _vm._v(" 发票："),
            _vm.orderDetail.invoiceUrl
              ? _c(
                  "span",
                  {
                    staticClass: "showInvice",
                    on: {
                      click: function($event) {
                        return _vm.downLoadInvoice(_vm.orderDetail.invoiceUrl)
                      }
                    }
                  },
                  [_vm._v("下载发票 ")]
                )
              : _c("span", [_vm._v("无")])
          ]),
          _c("div", { staticClass: "info-item" }, [
            _vm._v(
              " 下单时间：" +
                _vm._s(_vm._f("formatTime")(_vm.orderDetail.createTime)) +
                " "
            )
          ]),
          _vm.orderDetail.itemList
            ? [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.orderDetail.itemList, border: "" }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "货品规格", width: "180" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("skuCodeInfo")(scope.row.skuCode)
                                    ) +
                                    " "
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1585134986
                      )
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "designFile",
                        label: "文件",
                        width: "180"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.sourceWorkId
                                  ? _c(
                                      "el-button",
                                      {
                                        staticClass: "preview-button",
                                        attrs: { size: "mini" },
                                        on: {
                                          click: function($event) {
                                            return _vm.previewFile(
                                              scope.row.designFile
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" 文件预览 ")]
                                    )
                                  : _vm._e(),
                                scope.row.designFile && !scope.row.sourceWorkId
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          type: "warning"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.downLoadFile(
                                              scope.row.designFile
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" 文件下载 ")]
                                    )
                                  : _vm._e(),
                                !scope.row.designFile
                                  ? _c("span", [_vm._v("未上传")])
                                  : _vm._e()
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3385944549
                      )
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "price", label: "货品金额" }
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "quantity", label: "数量" }
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "", label: "订单金额" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        Number(_vm.orderDetail.orderAmount) +
                                          Number(scope.row.freight)
                                      ) +
                                      " "
                                  )
                                ]),
                                _c("div", [
                                  _vm._v(
                                    "(运费:" + _vm._s(scope.row.freight) + ")"
                                  )
                                ])
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3848641598
                      )
                    }),
                    _c(
                      "el-table-column",
                      { attrs: { prop: "", label: "发票状态" } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("invoiceStatusDesc")(
                                _vm.orderDetail.invoiceStatus
                              )
                            ) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "el-table-column",
                      { attrs: { prop: "", label: "订单状态" } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("orderStatusDesc")(
                                _vm.orderDetail.orderStatus
                              )
                            ) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            : _vm._e(),
          _c("h4"),
          _c("div", [
            _vm._v("物流单号：" + _vm._s(_vm.orderDetail.logisticsSerialNumber))
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.showOrderDetail = false
                    }
                  }
                },
                [_vm._v(" 确 定 ")]
              )
            ],
            1
          )
        ],
        2
      ),
      _vm.showPreview
        ? _c("preview-pic", {
            attrs: { files: _vm.showFile },
            on: {
              close: function() {
                _vm.showPreview = false
              }
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }