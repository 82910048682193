<template>
  <div>
    <div class="search-area">
      <div class="title">印刷订单</div>
      <div class="row">
        <div class="name">订单号</div>
        <div class="input"><el-input v-model="orderCode"></el-input></div>
        <div class="name mar-left">下单时间</div>
        <div class="input">
          <el-date-picker
            v-model="beginTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </div>
        <div class="space">至</div>
        <div class="input">
          <el-date-picker
            v-model="endTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </div>
        <div class="search-button" @click="search">
          <i class="el-icon-search"></i>搜索
        </div>
      </div>
      <!-- <div class='row'>

          </div> -->
    </div>
    <div class="order-class-list">
      <div
        @click="changeStatus(item)"
        :class="['order-class-item', { active: selectedIndex === item.index }]"
        v-for="item in stateList"
        :key="item.index"
      >
        {{ item.desc }}
      </div>
    </div>
    <div
      v-for="(item, index) in orderList"
      :key="index"
      style="margin-bottom: 30px; background-color: white; padding: 20px"
    >
      <el-row>
        <el-col :span="6"
          ><div>订单编号:{{ item.orderNo }}</div></el-col
        >
        <el-col :span="8"
          ><div>下单人:{{ item.userName }}</div></el-col
        >
        <el-col :span="8"><div></div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"
          ><div>下单时间:{{ item.createTime }}</div></el-col
        >
        <el-col :span="8"
          ><div>联系电话:{{ item.userPhone }}</div></el-col
        >
        <el-col :span="8"><div></div></el-col>
        <!-- {{orderDetailLog}} -->
      </el-row>
      <el-table
        :data="item.itemList"
        style="width: 100%"
        :header-cell-style="{
          'background-color': '#fafafa',
        }"
      >
        <el-table-column label="货品规格" width="200">
          <template slot-scope="scope">
            <div>{{ scope.row.productName }}</div>
            {{ scope.row.skuCode | skuCodeInfo }}
          </template>
        </el-table-column>

        <el-table-column prop="designFile" label="文件" width="200">
          <template slot-scope="scope">
            <el-button
              class="preview-button"
              size="mini"
              @click="previewFile(scope.row.designFile)"
              v-if="scope.row.sourceWorkId"
            >
              文件预览
            </el-button>
            <el-button
              v-if="scope.row.designFile && !scope.row.sourceWorkId"
              size="mini"
              type="warning"
              @click="downLoadFile(scope.row.designFile)"
              >文件下载</el-button
            >
            <span v-if="!scope.row.designFile">未上传</span>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="货品金额" width="100">
        </el-table-column>
        <el-table-column prop="quantity" label="数量" width="100">
        </el-table-column>
        <el-table-column
          prop="orderItemPayableAmount"
          label="订单金额"
          width="200"
        >
          <template slot-scope="scope">
            <div>
              {{ Number(item.orderAmount) + Number(scope.row.freight) }}
            </div>
            <div>(运费:{{ scope.row.freight }})</div>
          </template>
        </el-table-column>
        <el-table-column label="发票状态" width="200">
          <template slot-scope>
            {{ item.invoiceStatus | invoiceStatusDesc }}
          </template>
        </el-table-column>
        <el-table-column label="订单状态" width="200">
          <template>{{ item.orderStatus | orderStatusDesc }}</template>
        </el-table-column>
        <el-table-column prop="" label="其他" width="100">
          <template slot-scope>
            <div class="scope-div">
              <el-button size="mini" type="warning" @click="detailInfo(item)">
                订单详情
              </el-button>
            </div>
            <div
              class="scope-div"
              v-if="!item.invoiceStatus && item.orderStatus != '1'"
            >
              <el-button size="mini" type="warning" @click="addInvoice(item)">
                申请开票
              </el-button>
            </div>
            <div class="scope-div" v-if="item.orderStatus == '1'">
              <el-button size="mini" type="warning" @click="toPay(item)">
                去付款
              </el-button>
            </div>
            <div class="scope-div" v-if="item.orderStatus == '5'">
              <el-button
                size="mini"
                type="warning"
                @click="confirmReceipt(item)"
              >
                确认收货
              </el-button>
            </div>
            <div class="scope-div" v-if="item.invoiceStatus == 2">
              <el-button
                size="mini"
                type="warning"
                @click="downLoadInvoice(item.invoiceUrl)"
              >
                下载发票
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div align="right">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog title="选择发票" :visible.sync="showselectInvoice" width="50%">
      <div align="right">
        <el-button @click="$router.push({ name: 'InvioceInfo' })">
          管理
        </el-button>
      </div>
      <el-table :data="invoice" border style="width: 100%">
        <el-table-column width="180" align="center">
          <template slot-scope="scope">
            <el-radio v-model="selectedInvoceInfo" :label="scope.row.id">
              <span> </span>
            </el-radio>
          </template>
        </el-table-column>
        <el-table-column prop="ifVat" label="类型" width="180">
          <template slot-scope="scope">
            {{ scope.row.ifVat | invoiceType }}
          </template>
        </el-table-column>
        <el-table-column prop="vatInvoiceTitle" label="发票抬头">
        </el-table-column>
      </el-table>

      <span slot="footer" class="dialog-footer">
        <el-button @click="showselectInvoice = false">取 消</el-button>
        <el-button type="primary" @click="commitChange">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="订单详情" :visible.sync="showOrderDetail" width="50%">
      <h4>基本信息</h4>
      <div class="info-item">订单号：{{ orderDetail.orderNo }}</div>
      <div class="info-item">
        支付方式：{{ orderDetail.payType | payTypeDesc }}
      </div>
      <div class="info-item">下单人：{{ orderDetail.userName }}</div>
      <div class="info-item">联系电话：{{ orderDetail.userPhone }}</div>
      <div class="info-item">收货地址：{{ orderDetail.deliveryAddress }}</div>
      <div class="info-item">订单备注：{{ orderDetail.orderRemark }}</div>
      <template v-if="orderDetail.invoice">
        <div class="info-item" v-if="orderDetail.invoice.ifVat">
          发票类型：{{ orderDetail.invoice.ifVat | invoiceType }}
        </div>
        <div class="info-item" v-if="orderDetail.invoice.vatTaxpayerNum">
          纳税人识别号：{{ orderDetail.invoice.vatTaxpayerNum }}
        </div>
        <div class="info-item" v-if="orderDetail.invoice.vatInvoiceTitle">
          发票抬头：{{ orderDetail.invoice.vatInvoiceTitle }}
        </div>
        <div class="info-item" v-if="orderDetail.invoice.vatCompanyAddress">
          注册地址：{{ orderDetail.invoice.vatCompanyAddress }}
        </div>
        <div class="info-item" v-if="orderDetail.invoice.vatTelphone">
          注册电话：{{ orderDetail.invoice.vatTelphone }}
        </div>
        <div class="info-item" v-if="orderDetail.invoice.vatBankName">
          开户行：{{ orderDetail.invoice.vatBankName }}
        </div>
        <div class="info-item" v-if="orderDetail.invoice.vatBankAccount">
          银行帐号：{{ orderDetail.invoice.vatBankAccount }}
        </div>
      </template>

      <div class="info-item">
        发票：<span
          v-if="orderDetail.invoiceUrl"
          class="showInvice"
          @click="downLoadInvoice(orderDetail.invoiceUrl)"
          >下载发票
        </span>
        <span v-else>无</span>
      </div>
      <div class="info-item">
        下单时间：{{ orderDetail.createTime | formatTime }}
      </div>
      <template v-if="orderDetail.itemList">
        <el-table :data="orderDetail.itemList" border style="width: 100%">
          <el-table-column label="货品规格" width="180">
            <template slot-scope="scope">
              {{ scope.row.skuCode | skuCodeInfo }}
            </template>
          </el-table-column>
          <el-table-column prop="designFile" label="文件" width="180">
            <template slot-scope="scope">
              <el-button
                size="mini"
                class="preview-button"
                @click="previewFile(scope.row.designFile)"
                v-if="scope.row.sourceWorkId"
              >
                文件预览
              </el-button>
              <el-button
                v-if="scope.row.designFile && !scope.row.sourceWorkId"
                size="mini"
                type="warning"
                @click="downLoadFile(scope.row.designFile)"
              >
                文件下载
              </el-button>
              <span v-if="!scope.row.designFile">未上传</span>
            </template>
          </el-table-column>
          <el-table-column prop="price" label="货品金额"> </el-table-column>
          <el-table-column prop="quantity" label="数量"> </el-table-column>
          <el-table-column prop="" label="订单金额">
            <template slot-scope="scope">
              <div>
                {{
                  Number(orderDetail.orderAmount) + Number(scope.row.freight)
                }}
              </div>
              <div>(运费:{{ scope.row.freight }})</div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="发票状态">
            {{ orderDetail.invoiceStatus | invoiceStatusDesc }}
          </el-table-column>
          <el-table-column prop="" label="订单状态">
            {{ orderDetail.orderStatus | orderStatusDesc }}
          </el-table-column>
        </el-table>
      </template>
      <h4></h4>
      <div>物流单号：{{ orderDetail.logisticsSerialNumber }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showOrderDetail = false">
          确 定
        </el-button>
      </span>
    </el-dialog>
    <preview-pic
      v-if="showPreview"
      :files="showFile"
      @close="
        () => {
          showPreview = false;
        }
      "
    ></preview-pic>
  </div>
</template>

<script>
import { listByPage } from '@/api/baseInfo'
import util, { downLoadFile } from '@/lib/util'
import { queryPreview, printorderqueryPage, printorderapplyForInvoice, printordertakeDelivery } from '@/api/cubeclient'
import dayjs from 'dayjs'
import OrderUtil from '../../Order/common/OrderUtil'
import previewPic from '../../../components/previewPic.vue'
export default {
  components: { previewPic },
  data () {
    return {
      showPreview: false,
      orderCode: '',
      beginTime: '',
      endTime: '',
      orderList: [],
      showselectInvoice: false,
      orderDetail: {},
      showOrderDetail: false,
      state: [],
      total: 0,
      pageSize: 10,
      page: 0,
      invoice: [],
      selectedInvoceInfo: '',
      orderStatus: '',
      invoiceStatus: '',
      selectedIndex: 0,
      showFile: '',
      stateList: [
        { id: '', desc: '全部', index: 0, type: 'order' },
        { id: 1, desc: '待付款', index: 1, type: 'order' },
        { id: 2, desc: '已付款', index: 2, type: 'order' },
        { id: 3, desc: '待确认', index: 3, type: 'order' },
        { id: 4, desc: '待发货', index: 4, type: 'order' },
        { id: 5, desc: '已发货', index: 5, type: 'order' },
        { id: 6, desc: '已收货', index: 6, type: 'order' },
        { id: 0, desc: '未申请开票', index: 7, type: 'invoice' },
        { id: 1, desc: '待开票', index: 8, type: 'invoice' },
        { id: 2, desc: '已开票', index: 9, type: 'invoice' }
        // 1 待付款，2，已付款 ，3 待确认，4 待发货，5 已发货，6 已收货，7 取消
      ]
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    /**
     * 预览文件
     */
    async previewFile (file) {
      let filsList = file.split(",").map(item => {
        return item.substring(item.lastIndexOf("/") + 1);
      })
      let res = await queryPreview({
        "names": filsList,
        "type": "jpg"
      })

      if (res.data.length < 1) {
        this.$message.error('数据异常')
        return
      }
      this.showPreview = true
      this.showFile = res.data.map(item => {
        return `https://${item}`
      })
    },
    downLoadFile (url) {
      const type = url.split('.').pop()
      downLoadFile(url, type)
    },
    confirmReceipt (item) {
      this.$confirm('您确定要确认收货？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(async () => {

        await printordertakeDelivery({
          orderNo: item.orderCode
        })
        this.$message.success('确认收货成功')
        this.search()
      }).catch(() => { })
    },
    downLoadInvoice (href) {
      util.downLoadFile(href, 'pdf')
    },
    async commitChange () {
      this.showselectInvoice = false
      let invoiceInfo
      this.invoice.forEach(ele => {
        // eslint-disable-next-line eqeqeq
        if (ele.id == this.selectedInvoceInfo) {
          invoiceInfo = ele
        }
      })
      this.orderDetail.invoiceTitle = invoiceInfo.vatInvoiceTitle
      this.orderDetail.invoiceStatus = 1

      const param = {
        invoiceTitle: invoiceInfo ? JSON.stringify(invoiceInfo) : '',
        orderNo: this.orderDetail.orderCode,
      }
      const res = await printorderapplyForInvoice(param)
      // eslint-disable-next-line eqeqeq
      if (res.code == 200) {
        this.search()
      }
    },
    changeStatus (item) {
      // {id:6,desc:'已收货',index:6,type:'order'},
      // {id:0,desc:'未申请开票',index:7,type:'invoice'},
      this.selectedIndex = item.index
      this.page = 1
      // eslint-disable-next-line eqeqeq
      if (item.type == 'order') {
        this.orderStatus = item.id
        this.invoiceStatus = ''
      } else {
        this.orderStatus = ''
        this.invoiceStatus = item.id
      }
      this.search()
    },
    handleCurrentChange (val) {
      this.page = val
      this.search()
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.page = 1
      this.search()
    },
    async init () {
      this.orderCode = this.$route.query.orderSerialCode
      this.search()
    },
    async search () {

      const res = await printorderqueryPage({
        beginTime: this.beginTime,
        endTime: this.endTime,
        invoiceStatus: this.invoiceStatus,
        orderNo: this.orderCode,
        orderStatus: this.orderStatus,
        pageNo: this.page,
        pageSize: this.pageSize
      })
      this.orderList = res.data.list
      this.total = res.data.total

    },
    toPay (item) {
      this.$router.push({ name: 'Payment', params: { orderCode: [item.orderNo] } })
    },
    async addInvoice (item) {
      this.orderDetail = item
      this.showselectInvoice = true
      const invoceRes = await listByPage({
        page: 1,
        limit: 20
      })
      this.invoice = invoceRes.data.list
    },
    async detailInfo (item) {
      // const res = await searchOrderDetail({
      //   orderCode: item.orderCode
      // })
      this.orderDetail = item
      try {
        this.orderDetail.invoice = JSON.parse(this.orderDetail.invoiceTitle)
      } catch (err) {
        this.orderDetail.invoice = {}
        console.warn('发票信息异常')
        console.warn(err)
      }
      this.showOrderDetail = true
    }
  },
  computed: {

  },
  filters: {
    formatTime (time) {
      return dayjs(time).format('YYYY-MM-DD HH:mm:ss')
    },
    invoiceStatusDesc (index) {
      // 0 未申请开票 ，1 待开票 ， 2 已开票
      // console.log(index)
      switch (String(index)) {
        case '1':
          return '待开票'
        case '2':
          return '已开票'
        default:
          return '未申请开票'
      }
    },
    invoiceType: OrderUtil.invoiceType,
    orderStatusDesc (index) {
      // 1 待付款，2，已付款 ，3 待确认，4 待发货，5 已发货，6 已收货，7 取消
      switch (String(index)) {
        case '1':
          return '待付款'
        case '2':
          return '已付款'
        case '3':
          return '待确认'
        case '4':
          return '待发货'
        case '5':
          return '已发货'
        case '6':
          return '已收货'
        case '7':
          return '取消'
        default:
          return ''
      }
    },
    skuCodeInfo (value) {
      if (!value) {
        return ''
      }
      let result = ''
      const skuCodes = JSON.parse(value)
      skuCodes.forEach(skuCode => {
        for (const key in skuCode) {
          result = result + `${key}:${skuCode[key]}  `
        }
      })
      return result
    },
    stateName (value) {
      switch (value) {
        case 'all':
          return '全部'
        case 'waitingPayment':
          return '待付款'
        case 'paid':
          return '已付款'
        case 'toBeConfirmed':
          return '待确认'
        case 'toBeDelivered':
          return '待发货'
        case 'delivered':
          return '已发货'
        case 'receivedGoods':
          return '已收货'
        case 'notApplied':
          return '未申请开票'
        case 'toBeInvoiced':
          return '待开票'
        case 'invoiced':
          return '已开票'
        default:
          return ''
      }
    },
    payTypeDesc (value) {
      // eslint-disable-next-line eqeqeq
      if (value == 1) {
        return '微信支付'
        // eslint-disable-next-line eqeqeq
      } else if (value == 2) {
        return '支付宝支付'
      } else {
        return '未支付'
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.showInvice {
  cursor: pointer;
  color: #24c9f2;
}
.scope-div {
  margin: 10px;
}
.search-area {
  width: 100%;
  height: 150px;
  background: #ffffff;
  padding-left: 30px;
  .title {
    padding-top: 20px;
    font-size: 26px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #191c3d;
    margin-bottom: 30px;
  }
  .row {
    width: 100%;
    margin-bottom: 20px;
    display: inline-block;
    position: relative;
    .name {
      padding-right: 10px;
      float: left;
      font-size: 14px;
      line-height: 40px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #191c3d;
    }
    .input {
      float: left;
      width: 200px;
    }
    .space {
      float: left;
      line-height: 40px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      padding: 0 10px 0 10px;
      width: 80px;
      margin-left: 20px;
      text-align: center;
    }
    .mar-left {
      margin-left: 55px;
    }
    .select {
      float: left;
      width: 120px;
    }
    .search-button {
      position: absolute;
      right: 50px;
      width: 100px;
      height: 40px;
      background: #2468f2;
      border-radius: 4px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}
.order-class-list {
  margin-top: 16px;
  width: 100%;
  height: 56px;
  background: #cfcfcf;
  .active {
    background: #ffffff;
    pointer-events: none;
  }
  .order-class-item {
    float: left;
    width: 120px;
    height: 56px;

    text-align: center;
    line-height: 56px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #191c3d;
    cursor: pointer;
  }
}

.order-item {
  width: 100%;
  background: #ffffff;
  margin-top: 20px;
  padding: 20px;
}
.info-item {
  width: 50%;
  float: left;
  line-height: 30px;
}
.preview-button {
  cursor: pointer;
  &:hover {
    color: rgb(0, 162, 255);
  }
}
</style>
