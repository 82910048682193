import { getDefaultAddress, queryAddressByPage, setDefaultAddress, listByPage } from '@/api/baseInfo'
import { updateInvoice, deleteInvoice, deleteAddress } from '@/api/userInfo'
const OrderUtil = {
  toReceivingAddress () {
    const { href } = this.$router.resolve({ name: 'ReceivingAddress' })
    window.open(href, '_blank')
  },
  async showAllAdressOper () {
    const addres = await queryAddressByPage({
      page: 1,
      limit: 20
    })
    if (addres.code == 0) {
      this.allAddress = addres.data.list
    }
    this.showAllAdress = true
  },
  async commitAddress (param) {
    // setTimeout(async ()=>{
    //     let res = await getDefaultAddress({})//获取默认地址
    //    this.defaultAddress = res.data||{}
    // },1000)
    this.defaultAddress = param
    this.showaddAdress = false
    this.showEditAdress = false
  },
  async deleteAddress () {
    this.$confirm('删除该地址后不可恢复', '确定删除地址？', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(async () => {
      await deleteAddress({ id: this.defaultAddress.id })
      this.defaultAddress = {}

    }).catch(() => {

    })
  },
  addInvoiceButtonEvent () {
    this.showselectInvoice = true
    if (this.invoice.length === 1) {
      this.selectedId = this.invoice[0].id
    }
  },

  addressDetail () {
    // +this.defaultAddress.streetName
    return `${this.defaultAddress.provinceName || ''}${this.defaultAddress.cityName || ''}${this.defaultAddress.areaName || ''}${this.defaultAddress.address || ''}`
  },
  selectedInvoceInfo () {
    let result = {}
    this.invoice.forEach(ele => {
      if (String(ele.id) == String(this.selectedId)) {
        result = ele
      }
    })

    return result
  },
  selectInvoice () {
    if (this.selectedId == '') {
      this.$message('未选择发票')
      return
    }
    this.showselectInvoice = false
    this.invoiceStatus = 1
  },
  skuCodeInfo (value) {
    if (!value) {
      return ''
    }
    let result = ''
    const skuCodes = JSON.parse(value)
    skuCodes.forEach(skuCode => {
      for (const key in skuCode) {
        result = result + `${key}:${skuCode[key]}  `
      }
    })
    return result
  },
  invoiceType (value) {
    // <el-radio :label="1">增值普通发票</el-radio>
    // <el-radio :label="2">增值专用发票</el-radio>
    // <el-radio :label="3">个人</el-radio>
    switch (value) {
      case 1:
        return '增值普通发票'
        break
      case 2:
        return '增值专用发票'
        break
      default:
        return '个人'
    }
  },
  async submit () {
    this.showAddInvoice = false
    const invoceRes = await listByPage({
      page: 1,
      limit: 20
    })
    this.invoice = invoceRes.data.list
  },
  async editSubmit () {
    this.showEditInvoice = false
    const invoceRes = await listByPage({
      page: 1,
      limit: 20
    })
    this.invoice = invoceRes.data.list
  },
  cancelAddInvoice () {
    this.showAddInvoice = false
  },
  cancelShowEditInvoice () {
    this.showEditInvoice = false
  },
  async changeDefault (obj) {
    await setDefaultAddress({ id: obj.id })
  },
  deleteButtonEvent () {
    this.$confirm('删除该发票信息后不可恢复', '确定删除发票信息？', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.deleteInvoice()
    }).catch(() => {

    })
  },
  async deleteInvoice () {
    const res = await deleteInvoice([this.selectedInvoceInfo.id])
    this.invoiceStatus = 0
    this.selectedId = ''
    this.submit()
  }
}

export default OrderUtil
