<template>
  <div>
    <div class="mask" @click="$emit('close')"></div>
    <div class="preview-area">
      <div class="content">
        <img
          class="pre-content"
          :src="file"
          v-for="(file, index) in files"
          :key="index"
        />
        <div class="water"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    files: {
      type: Array,
      default: []
    }
  },
  data () {
    return {}
  },


}
</script>
<style lang="scss" scoped>
.preview-area {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 60%;
  height: 80%;
  overflow-y: scroll;
  background-color: white;
  box-sizing: border-box;
  text-align: center;
  .pre-content {
    width: 70%;
    margin-bottom: 60px;
    margin-top: 60px;
  }
}
.mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9000;
  background-color: #0000002d;
}
.water {
  width: 100%;
  height: 100%;
  background-image: url("~@/assets/watermark.svg");
  position: absolute;
  z-index: 99;
  top: 0;
}
.content {
  position: relative;
}
</style>