import Request from '@/lib/Request'
const http = new Request(process.env.VUE_APP_URL)
// 保存地址
export const saveAddress = params => {
  return http.post('/cube_web_api/login/user/saveAddress', params)
}
// 默认地址
export const getDefaultAddress = params => {
  return http.get('/cube_web_api/login/user/getDefaultAddress', params)
}
// 发票列表
export const listByPage = params => {
  return http.post('/cube_web_api/sys/invoice/listByPage', params)
}

// 添加发票
export const addInvoice = params => {
  return http.post('/cube_web_api/sys/invoice/save', params)
}
// 所有地址
export const queryAddressByPage = params => {
  return http.post('/cube_web_api/login/user/queryAddressByPage', params)
}
// 所有地址
export const setDefaultAddress = params => {
  return http.get('/cube_web_api/login/user/setDefaultAddress', params)
}
