// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/watermark.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".preview-area[data-v-4368e49c] {\n  position: fixed;\n  z-index: 9999;\n  top: 50%;\n  left: 50%;\n  transform: translateX(-50%) translateY(-50%);\n  width: 60%;\n  height: 80%;\n  overflow-y: scroll;\n  background-color: white;\n  box-sizing: border-box;\n  text-align: center;\n}\n.preview-area .pre-content[data-v-4368e49c] {\n    width: 70%;\n    margin-bottom: 60px;\n    margin-top: 60px;\n}\n.mask[data-v-4368e49c] {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  z-index: 9000;\n  background-color: #0000002d;\n}\n.water[data-v-4368e49c] {\n  width: 100%;\n  height: 100%;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  position: absolute;\n  z-index: 99;\n  top: 0;\n}\n.content[data-v-4368e49c] {\n  position: relative;\n}\n", ""]);
// Exports
module.exports = exports;
